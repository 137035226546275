import { auth } from "./firebase";
import Home from "./appPages/Home";
import Login from "./appPages/Login";
import Nav from "./appComponents/Nav";
import Profile from "./appPages/Profile";
import Register from "./appPages/Register";
import Settings from "./appPages/Settings";
import StarChat from "./starchat/StarChat";
import StarNote from "./starnote/StarNote";
import { useState, useEffect } from "react";
import ErrorPage from "./appPages/ErrorPage";
import ReportBug from "./appPages/ReportBug";
// import StarSafe from "./starsafe/StarSafe";
import { onAuthStateChanged } from "firebase/auth";
import StarConnect from "./starconnect/StarConnect";
import StarNoteBin from "./starnote/subPages/StarNoteBin";
import LoginInfo from "./appPages/documentation/LoginInfo";
import About from "./appPages/documentation/AboutStarrvault";
import StarNoteNotes from "./starnote/subPages/StarNoteNotes";
import AccountInfo from "./appPages/documentation/AccountInfo";
import StarChatInfo from "./appPages/documentation/StarChatInfo";
import StarNoteInfo from "./appPages/documentation/StarNoteInfo";
import StarNoteArchive from "./starnote/subPages/StarNoteArchive";
import StarNoteStarred from "./starnote/subPages/StarNoteStarred";
import Documentation from "./appPages/documentation/Documentation";
import StarConnectInfo from "./appPages/documentation/StarConnectInfo";
import StarNoteLabelsView from "./starnote/subPages/StarNoteLabelsView";
import RegistrationInfo from "./appPages/documentation/RegistrationInfo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AccountSettings from "./appComponents/settingsComponents/AccountSettings";
import StarChatSettings from "./appComponents/settingsComponents/StarChatSettings";
import StarNoteSettings from "./appComponents/settingsComponents/StarNoteSettings";
import StarSafeSettings from "./appComponents/settingsComponents/StarSafeSettings";
import StarConnectSettings from "./appComponents/settingsComponents/StarConnectSettings";
import Careers from "./appPages/Careers";

function App() {
  const [userState, setUserState] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserState(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div
        className={`flex ${userState ? (isNavOpen ? "ml-60" : "ml-10") : ""}`}
      >
        {userState && <Nav isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />}
        <div className="flex-grow p-4">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/register"
              element={userState ? <Home /> : <Register />}
            />
            <Route path="/login" element={userState ? <Home /> : <Login />} />
            <Route
              path="/profile"
              element={userState ? <Profile /> : <Register />}
            />
            <Route
              path="/settings"
              element={userState ? <Settings /> : <Register />}
            >
              <Route
                path="account"
                element={<AccountSettings isNavOpen={isNavOpen} />}
              />
              <Route
                path="starchat"
                element={<StarChatSettings isNavOpen={isNavOpen} />}
              />
              <Route path="starnote" element={<StarNoteSettings />} />
              <Route
                path="starconnect"
                element={<StarConnectSettings isNavOpen={isNavOpen} />}
              />
              <Route path="starsafe" element={<StarSafeSettings />} />
            </Route>
            <Route
              path="/starnote/*"
              element={userState ? <StarNote /> : <Register />}
            >
              <Route path="bin" element={<StarNoteBin />} />
              <Route path="notes" element={<StarNoteNotes />} />
              <Route path="archive" element={<StarNoteArchive />} />
              <Route path="labels/starred" element={<StarNoteStarred />} />
              <Route path="labels/:label" element={<StarNoteLabelsView />} />
            </Route>
            <Route
              path="/starchat/*"
              element={userState ? <StarChat /> : <Register />}
            />
            <Route
              path="/starconnect/*"
              element={userState ? <StarConnect /> : <Register />}
            >
              <Route
                path="archive"
                element={userState ? <StarConnect /> : <Register />}
              />
              <Route path="archive/:contact" />
              <Route
                path="new"
                element={userState ? <StarConnect /> : <Register />}
              />
              <Route
                path=":contact"
                element={userState ? <StarConnect /> : <Register />}
              />
            </Route>
            {/* <Route
              path="/starsafe"
              element={userState ? <StarSafe /> : <Register />}
            /> */}
            <Route path="/documentation" element={<Documentation />}>
              <Route path="about" element={<About />} />
              <Route path="login" element={<LoginInfo />} />
              <Route path="account" element={<AccountInfo />} />
              <Route path="starchat" element={<StarChatInfo />} />
              <Route path="starnote" element={<StarNoteInfo />} />
              <Route path="starconnect" element={<StarConnectInfo />} />
              <Route path="registration" element={<RegistrationInfo />} />
            </Route>
            <Route path="/careers" element={<Careers />} />
            <Route
              path="/report-bug-or-suggestion"
              element={userState ? <ReportBug /> : <Register />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
