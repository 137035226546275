import React from "react";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiEdit, FiMessageSquare, FiUsers } from "react-icons/fi";

function SettingsSidebar() {
  return (
    <div className="w-[10rem] flex flex-col h-full">
      {/* Sidebar Heading */}
      <h2 className="text-custom-gray-1 text-2xl font-medium mb-4">Settings</h2>

      {/* Menu List */}
      <ul className="-ml-2 w-[10rem] flex-grow flex flex-col gap-y-1">
        {/* Account */}
        <Link to="/settings/account" className="text-md font-medium">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <FaUser className="mr-2" />
            Account
          </li>
        </Link>

        {/* Star Chat */}
        <Link to="/settings/starchat" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiMessageSquare className="mr-2" />
              Star Chat
            </div>
          </li>
        </Link>

        {/* Star Note */}
        <Link to="/settings/starnote" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiEdit className="mr-2" />
              Star Note
            </div>
          </li>
        </Link>

        {/* Star Connect */}
        <Link to="/settings/starconnect" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiUsers className="mr-2" />
              Star Connect
            </div>
          </li>
        </Link>
      </ul>

      {/* Navigation links */}
      <div className="flex justify-between">
        {/* Documentation */}
        <Link
          to="/documentation"
          className="text-sm text-blue-500 hover:underline self-start"
        >
          See Docs
        </Link>

        {/* Documentation */}
        <Link
          to="/careers"
          className="text-sm text-blue-500 hover:underline self-start"
        >
          Careers
        </Link>
      </div>
    </div>
  );
}

export default SettingsSidebar;
