import { FiStar } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

function LoginRegisterTile() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center py-1 px-2">
      <div className="flex items-center">
        <FiStar className="text-yellow-400 mr-1" size="20" />
        <Link to="/" className="text-lg font-bold">
          starrvault
        </Link>
      </div>
      <div className="flex space-x-14">
        <li
          className="list-none cursor-pointer hover:underline"
          onClick={() => navigate("/documentation")}
          title="See Documentation"
        >
          Docs
        </li>
        <li
          className="list-none cursor-pointer hover:underline"
          onClick={() => navigate("/careers")}
          title="See Documentation"
        >
          Careers
        </li>
      </div>
      <ul className="flex space-x-4 list-none m-0">
        <li
          className="px-4 py-2 rounded cursor-pointer shadow hover:shadow-yellow-400 duration-150 ease-in-out"
          onClick={() => navigate("/register")}
        >
          Register
        </li>
        <li
          className="px-6 py-2 rounded cursor-pointer shadow hover:shadow-yellow-400 duration-150 ease-in-out"
          onClick={() => navigate("/login")}
        >
          Login
        </li>
      </ul>
    </div>
  );
}

export default LoginRegisterTile;
