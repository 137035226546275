import { auth } from "../firebase";
import { FaRegClock } from "react-icons/fa";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";
import { FaIndianRupeeSign, FaLocationDot } from "react-icons/fa6";

function Careers({ isNavOpen }) {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  // Listen for authentication changes, and update the state.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserSignedIn(true);
      } else {
        setIsUserSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      {!isUserSignedIn && <LoginRegisterTile />}
      <div className={`${isNavOpen ? "ml-70" : "ml-10"}`}>
        <h2 className="text-3xl font-medium text-gray-800 mt-4">Careers</h2>
        {/* Card */}
        <div className="w-[35rem] h-[14rem] border border-gray-200 rounded-sm p-2 hover:shadow-md duration-300 ease-in-out mt-4">
          <div className="flex justify-between items-center mx-1">
            <span className="text-xs font-medium text-gray-500 mt-1">
              Position
            </span>
            <span className="font-light text-xs text-gray-600 mt-1">
              5 days ago
            </span>
          </div>
          <span className="text-[2.8rem] font-doto ml-2">
            COO/CTO/CO-FOUNDER
          </span>
          <div className="flex space-x-3 ml-2">
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaIndianRupeeSign className="my-auto mr-1" />
              Unavailable
            </span>
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaRegClock className="my-auto mr-1" />
              Full-time
            </span>
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaLocationDot className="my-auto mr-1" />
              Aurangabad
            </span>
          </div>
          <p className="text-sm text-gray-600 mt-2 ml-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia
            cupiditate ipsa alias odit voluptatem minima delectus debitis maxime
            aperiam? Id nulla aliquam minus provident doloremque veritatis
            nesciunt sapiente facilis!
          </p>
        </div>

        {/* Card */}
        <div className="w-[35rem] h-[14rem] border border-gray-200 rounded-sm p-2 hover:shadow-lg hover:shadow-yellow-200 duration-300 ease-in-out mt-4">
          <div className="flex justify-between items-center mx-1">
            <span className="text-xs font-medium text-gray-500 mt-1">
              Position
            </span>
            <span className="font-light text-xs text-gray-600 mt-1">
              5 days ago
            </span>
          </div>
          <span className="text-[2.7rem] ml-2 font-doto">
            SOCIAL MEDIA MANAGER
          </span>
          <div className="flex space-x-3 ml-2">
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaIndianRupeeSign className="my-auto mr-1" />
              Unavailable
            </span>
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaRegClock className="my-auto mr-1" />
              Full-time
            </span>
            <span className="flex py-1 px-[0.35rem] text-gray-600 font-medium text-sm border border-gray-300 rounded">
              <FaLocationDot className="my-auto mr-1" />
              Aurangabad
            </span>
          </div>
          <p className="text-sm text-gray-600 mt-2 ml-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia
            cupiditate ipsa alias odit voluptatem minima delectus debitis maxime
            aperiam? Id nulla aliquam minus provident doloremque veritatis
            nesciunt sapiente facilis!
          </p>
        </div>
      </div>
    </>
  );
}

export default Careers;
