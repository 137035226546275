import { auth } from "../firebase";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";

function NotFoundPage({ isNavOpen }) {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  // Listen for authentication changes, and update the state.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserSignedIn(true);
      } else {
        setIsUserSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      {!isUserSignedIn && <LoginRegisterTile />}
      <div
        className={`text-center sm:mt-8 lg:mt-52 ${
          isUserSignedIn ? "" : isNavOpen ? "ml-10" : "ml-70"
        }`}
      >
        <h1 className="text-6xl font-bold text-red-600">404</h1>
        <p className="text-2xl font-semibold ml-4">Page Not Found</p>
        <p className="text-md mb-4 text-gray-600">
          The page you are looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          Go to Homepage
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
